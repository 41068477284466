import { PageNotFound } from ":src/components/route";
import Layout from "./layout";
import { Route, Switch } from "wouter";
import { WordCloud } from ":src/modules/wordcloud";
import { Poll } from ":src/modules/poll";
import { OpenQuestions } from ":src/modules/open-questions";
export default function PlayRouter() {
  // const app_flavor = global.store.hooks.whitelabel.useAppFlavor();
  return (
    <Layout>
      <Switch>
        {/* pages */}
        <Route path="/wordCloud/:eventId" children={<WordCloud />} />
        <Route path="/poll/:eventId" children={<Poll />} />
        <Route path="/open-question/:id" children={<OpenQuestions />} />
        <Route children={<PageNotFound />} />
      </Switch>
    </Layout>
  );
}
